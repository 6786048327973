<template>
  <Head>
    <title>COSTA Cruise's</title>
  </Head>

  <header class="header-list header-list--cruiser">
    <Main-nav></Main-nav>
  </header>
  <main class="main main--cruiser">
    <div class="search-filters search-filters--cruiser">
      <div class="search-filters__filters">
        <section class="search-filters__main-filter">
          <nav class="search-filters__nav">
            <router-link
              to="/vyhledat/"
              class="search-filters__nav-item search-filters__nav-item--cruiser"
            >
              <svg
                class="
                  search-filters__nav-icon search-filters__nav-icon--cruiser
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#cruiser"></use>
              </svg>
              <span class="search-filters__nav-name">{{
                $t('LABELS.cruiser')
              }}</span>
            </router-link>
          </nav>
          <main
            class="
              search-filters__filter-container
              search-filters__filter-container--cruiser
            "
          >
            <div class="search-filters__filter-wrapper">
              <svg
                class="
                  search-filters__filter-icon
                  search-filters__filter-icon--cruiser
                  search-filters__filter-icon--pinpoint
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#pinpoint"></use>
              </svg>
              <v-select
                class="
                  search-filters__iconed-select
                  search-filters__iconed-select--cruiser
                "
                :options="langDestinations"
                :reduce="(destination) => destination.code"
                label="langName"
                v-model="destination"
                @close="destinationChanged"
              ></v-select>
            </div>
            <div class="search-filters__filter-wrapper">
              <svg
                class="
                  search-filters__filter-icon
                  search-filters__filter-icon--cruiser
                  search-filters__filter-icon--calendar
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#calendar"></use>
              </svg>
              <input
                type="date"
                id="start-date"
                class="search-filters__input search-filters__input--clickable"
                v-model="startDate"
              />
            </div>
            <div class="search-filters__filter-wrapper">
              <svg
                class="
                  search-filters__filter-icon
                  search-filters__filter-icon--cruiser
                  search-filters__filter-icon--calendar
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#calendar"></use>
              </svg>
              <input
                type="date"
                id="end-date"
                class="search-filters__input search-filters__input--clickable"
                v-model="endDate"
              />
            </div>
            <div
              class="search-filters__filter-wrapper"
              v-click-outside="hideCrewPopup"
              @click="showCrewPopup"
            >
              <svg
                class="
                  search-filters__filter-icon
                  search-filters__filter-icon--cruiser
                  search-filters__filter-icon--people
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#people"></use>
              </svg>
              <input
                type="text"
                class="
                  search-filters__input
                  search-filters__input--readonly
                  search-filters__input--clickable
                "
                :value="`${adultsNum} ${adultsInflected}, ${childrenNum} ${childrenInflected}`"
                readonly
              />
              <div
                class="search-filters__popup search-filters__popup--crew"
                v-show="isCrewPopupShown"
              >
                <Counter
                  :min="1"
                  :initial="adultsNum"
                  :max="adultsLimit"
                  :new-val="newAdultsNum"
                  :title="$t('LABELS.adults-with-age')"
                  @changed="updateAdultsNum"
                ></Counter>
                <Counter
                  :min="0"
                  :initial="childrenNum"
                  :max="childrenLimit"
                  :new-val="newChildrenNum"
                  :title="$t('LABELS.children-with-age')"
                  @changed="updateChildrenNum"
                ></Counter>
              </div>
            </div>
            <div class="search-filters__filter-wrapper">
              <svg
                class="
                  search-filters__filter-icon
                  search-filters__filter-icon--cruiser
                  search-filters__filter-icon--cruiser-icon
                "
              >
                <use href="/assets/imgs/icons/sprite.svg#cruiser"></use>
              </svg>
              <v-select
                class="
                  search-filters__iconed-select
                  search-filters__iconed-select--cruiser
                "
                :options="langCruisers"
                :reduce="(cruiser) => cruiser.code"
                label="langName"
                v-model="shipCode"
                @close="shipCodeChanged"
              ></v-select>
            </div>
            <!-- <div class="search-filters__filter-wrapper search-filters__filter-wrapper--self">
                            <input type="checkbox" class="search-filters__checkbox search-filters__checkbox--large" id="transport-included" v-model="transportIncluded">
                            <label class="search-filters__checkbox-label" for="transport-included">{{ $t('LABELS.includes-transport) }}</label>
                        </div> -->
          </main>
        </section>
        <section
          class="search-filters__optional-filters"
          :class="{
            'search-filters__optional-filters--show-advanced': showAdvanced,
            'search-filters__optional-filters--shown': isShownMobileFiltering,
          }"
        >
          <div
            class="
              search-filters__modal-header search-filters__modal-header--cruiser
            "
          >
            <svg
              class="search-filters__modal-close-icon"
              @click="hideMobileFiltering"
            >
              <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
            </svg>
            <p class="search-filters__modal-header-title">
              {{ $t('LABELS.filter') }}
            </p>
          </div>
          <div
            class="
              search-filters__filter-container
              search-filters__filter-container--bordered
            "
          >
            <h3
              class="
                search-filters__heading
                search-filters__heading--cruiser
                search-filters__heading--optional-filters
              "
            >
              {{ $t('LABELS.departure-from-port') }}
            </h3>
            <v-select
              class="search-filters__select search-filters__select--cruiser"
              :options="langPorts"
              :reduce="(port) => port.code"
              label="langName"
              v-model="departurePort"
            ></v-select>
          </div>
          <div
            class="
              search-filters__filter-container
              search-filters__filter-container--bordered
            "
          >
            <h3
              class="
                search-filters__heading
                search-filters__heading--cruiser
                search-filters__heading--optional-filters
              "
            >
              {{ $t('LABELS.sail-duration') }}
            </h3>
            <v-select
              class="search-filters__select search-filters__select--cruiser"
              :options="langDaysMax"
              :reduce="(dayMax) => dayMax.durationMax"
              label="langName"
              v-model="cruiseDaysMax"
            ></v-select>
          </div>
          <div
            class="
              search-filters__filter-container
              search-filters__filter-container--bordered
            "
          >
            <h3
              class="
                search-filters__heading
                search-filters__heading--cruiser
                search-filters__heading--optional-filters
              "
            >
              {{ $t('LABELS.sail-one-way') }}
            </h3>
            <v-select
              class="search-filters__select search-filters__select--cruiser"
              :options="oneWaySails"
              :reduce="(oneWay) => oneWay.value"
              label="langName"
              v-model="cruiseOneWay"
            ></v-select>
          </div>
          <div
            class="
              search-filters__filter-container
              search-filters__filter-container--bordered
            "
          >
            <h3
              class="
                search-filters__heading
                search-filters__heading--cruiser
                search-filters__heading--optional-filters
              "
            >
              {{ $t('LABELS.sail-type') }}
            </h3>
            <v-select
              class="search-filters__select search-filters__select--cruiser"
              :options="onlyPromosOptions"
              :reduce="(onlyPromoOptions) => onlyPromoOptions.onlyPromo"
              label="langName"
              v-model="onlyPromo"
            ></v-select>
          </div>
          <div
            class="
              search-filters__filter-container
              search-filters__filter-container--bordered
            "
          >
            <h3
              class="
                search-filters__heading
                search-filters__heading--cruiser
                search-filters__heading--optional-filters
                search-filters__heading--price
              "
            >
              {{ $t('LABELS.price-for-cabin') }}
            </h3>
            <slider
              v-model="price"
              :min="0"
              :max="priceLimit[$store.currency]"
              :step="100"
              :tooltips="false"
              @change="priceChanged"
            ></slider>
            <div class="search-filters__notes search-filters__notes--cruiser">
              <span class="search-filters__price search-filters__price--min"
                >{{ formatPrice(price[0]) }}
                {{ $store.currency === 'czk' ? 'Kč' : '€' }}</span
              >
              <span class="search-filters__price search-filters__price--max"
                >{{ formatPrice(price[1])
                }}{{ price[1] >= priceLimit[$store.currency] ? '+' : '' }}
                {{ $store.currency === 'czk' ? 'Kč' : '€' }}</span
              >
            </div>
          </div>
          <button
            class="
              search-filters__sort-btn search-filters__sort-btn--filtering
              btn btn--blue btn--standalone
            "
            @click="hideMobileFiltering"
          >
            {{ $t('LABELS.save') }}
          </button>
          <!-- <div class="search-filters__filter-container search-filters__filter-container--bordered">
                        <h3 class="search-filters__heading search-filters__heading--cruiser">{{ $t('LABELS.special-offers) }}</h3>
                        <div class="search-filters__checkbox-wrapper">
                            <input type="checkbox" class="search-filters__checkbox" id="total-comfort" v-model="totalComfort">
                            <label for="total-comfort" class="search-filters__checkbox-label search-filters__checkbox-label--dark">{{ $t('TEXTS.total-comfort) }}</label>
                        </div>
                    </div> -->
        </section>
        <section class="search-filters__banners">
          <Banner
            class="search-filters__banner"
            v-for="banner in banners"
            :key="banner"
            :type="banner.desktop.type"
            :source="banner.desktop.source"
            :url="banner.url"
          >
          </Banner>
        </section>
      </div>
      <div class="search-filters__main">
        <div
          class="search-filters__sortings"
          :class="{
            'search-filters__sortings--show-advanced': showAdvanced,
            'search-filters__sortings--shown': isShownMobileSorting,
          }"
        >
          <div
            class="
              search-filters__modal-header search-filters__modal-header--cruiser
            "
          >
            <svg
              class="search-filters__modal-close-icon"
              @click="hideMobileSorting"
            >
              <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
            </svg>
            <p class="search-filters__modal-header-title">
              {{ $t('LABELS.sort') }}
            </p>
          </div>
          <h3
            class="
              search-filters__sort-heading search-filters__sort-heading--cruiser
            "
          >
            {{ $t('LABELS.sort-by') }}
          </h3>
          <div class="search-filters__sorting-wrapper">
            <input
              type="radio"
              name="sort"
              id="soon"
              value="soon"
              class="search-filters__sorting-radio"
              v-model="sortBy"
            />
            <label
              for="soon"
              class="
                search-filters__sorting-label
                search-filters__sorting-label--cruiser
              "
              >{{ $t('LABELS.soonest-departure') }}</label
            >
          </div>
          <div class="search-filters__sorting-wrapper">
            <input
              type="radio"
              name="sort"
              id="cheapest"
              value="cheapest"
              class="search-filters__sorting-radio"
              v-model="sortBy"
            />
            <label
              for="cheapest"
              class="
                search-filters__sorting-label
                search-filters__sorting-label--cruiser
              "
              >{{ $t('LABELS.from-cheapest') }}</label
            >
          </div>
          <div class="search-filters__sorting-wrapper">
            <input
              type="radio"
              name="sort"
              id="shortest"
              value="shortest"
              class="search-filters__sorting-radio"
              v-model="sortBy"
            />
            <label
              for="shortest"
              class="
                search-filters__sorting-label
                search-filters__sorting-label--cruiser
              "
              >{{ $t('LABELS.shortest') }}</label
            >
          </div>
          <div class="search-filters__sorting-wrapper">
            <input
              type="radio"
              name="sort"
              id="longest"
              value="longest"
              class="search-filters__sorting-radio"
              v-model="sortBy"
            />
            <label
              for="longest"
              class="
                search-filters__sorting-label
                search-filters__sorting-label--cruiser
              "
              >{{ $t('LABELS.longest') }}</label
            >
          </div>
          <!-- <hr class="search-filters__sort-hr">
                    <div class="search-filters__price-switches">
                        <p class="search-filters__price-switches-heading search-filters__price-switches-heading--cruiser">{{ $t('LABELS.show-price') }}</p>
                        <input type="radio" name="show-price" value="cabin" id="price-cabin" class="search-filters__price-switch" v-model="priceType">
                        <label for="price-cabin" class="search-filters__price-switch-label search-filters__price-switch-label--cruiser">{{ $t('LABELS.for-cabin') }}</label>
                        <input type="radio" name="show-price" value="single" id="price-single" class="search-filters__price-switch" v-model="priceType">
                        <label for="price-single" class="search-filters__price-switch-label search-filters__price-switch-label--cruiser">{{ $t('LABELS.for-person') }}</label>
                    </div> -->
          <hr class="search-filters__sort-hr" />
          <button
            class="search-filters__sort-btn btn btn--blue btn--standalone"
            @click="hideMobileSorting"
          >
            {{ $t('LABELS.save') }}
          </button>
        </div>
        <div
          class="
            search-filters__show-advanced search-filters__show-advanced--large
          "
          @click="toggleAdvanced"
        >
          <p class="search-filters__show-advanced-text">
            {{ showAdvanced ? $t('LABELS.hide') : $t('LABELS.show') }}
            {{ $t('LABELS.advanced-filtering').toLowerCase() }}
          </p>
        </div>
        <div
          class="
            search-filters__show-advanced search-filters__show-advanced--small
          "
        >
          <button
            class="search-filters__show-advanced-option"
            @click="showMobileSorting"
          >
            <svg
              class="
                search-filters__show-advanced-icon
                search-filters__show-advanced-icon--sort
              "
            >
              <use href="/assets/imgs/icons/sprite.svg#sort"></use>
            </svg>
            {{ $t('LABELS.sort') }}
          </button>
          <button
            class="search-filters__show-advanced-option"
            @click="showMobileFiltering"
          >
            <svg
              class="
                search-filters__show-advanced-icon
                search-filters__show-advanced-icon--filter
              "
            >
              <use href="/assets/imgs/icons/sprite.svg#filter"></use>
            </svg>
            {{ $t('LABELS.filter') }}
          </button>
        </div>
        <div class="search-filters__content">
          <showcase-item-wide
            class="search-filters__item-wide"
            v-for="cruise in searchedCruises"
            :key="cruise.cruise_id"
            type="cruiser"
            :duration="cruise.days"
            :transport-included="cruise.transport_included"
            :image-url="cruise.image"
            :departure-city="cruise.departure_port_name[LANGUAGE]"
            :destination="cruise.destination_name[LANGUAGE]"
            :itinerary="cruise.itinerary[LANGUAGE]"
            :departure-date="cruise.departure_date"
            :arrival-date="cruise.arrival_date"
            :price="cruise.singleForTwoPrice[$store.currency]"
            :price-full="cruise.fullPrice[$store.currency]"
            :second-price="cruise.priceDouble[$store.currency]"
            :second-price-label="$t('TEXTS.price-for-two-from')"
            :code="cruise.cruise_code"
            :is-single-price="priceType === 'single'"
            :cruiser-name="cruise.ship_name[LANGUAGE]"
            :url-suffix="`adultsNum=${adultsNum}&childrenNum=${childrenNum}`"
            :promo-name="cruise.promo_name"
          ></showcase-item-wide>
          <showcase-item
            class="search-filters__item"
            v-for="cruise in searchedCruises"
            :key="cruise.cruise_id"
            type="cruiser"
            :duration="cruise.days"
            :transport-included="cruise.transport_included"
            :image-url="cruise.image"
            :departure-city="cruise.departure_port_name[LANGUAGE]"
            :destination="cruise.destination_name[LANGUAGE]"
            :itinerary="cruise.itinerary[LANGUAGE]"
            :departure-date="cruise.departure_date"
            :arrival-date="cruise.arrival_date"
            :price="cruise.singleForTwoPrice[$store.currency]"
            :price-full="cruise.fullPrice[$store.currency]"
            :second-price="cruise.priceDouble[$store.currency]"
            :second-price-label="$t('TEXTS.price-for-two-from')"
            :code="cruise.cruise_code"
            :is-single-price="priceType === 'single'"
            :cruiser-name="cruise.ship_name[LANGUAGE]"
            :url-suffix="`adultsNum=${adultsNum}&childrenNum=${childrenNum}`"
            :promo-name="cruise.promo_name"
          ></showcase-item>
          <p
            class="search-filters__no-results"
            v-if="!searching && searchedCruises.length === 0"
          >
            {{ $t('TEXTS.no-searched-cruises') }}
          </p>
        </div>
        <Paging
          :max-page="maxPage"
          :current-page="currentPage"
          @changed-page="changePage"
        ></Paging>
      </div>
    </div>
  </main>
  <Footer></Footer>
  <Loading
    :is-loading="isLoading"
    :label="$t('STATUSES.loading-page')"
  ></Loading>
  <Loading
    :is-loading="!isLoading && searching"
    :label="$t('STATUSES.searching-trips')"
  ></Loading>
</template>

<script>
import Slider from '@vueform/slider';
import moment from 'moment';
import vSelect from 'vue-select';

import ClickOutside from '../../modules/vendor/vue-click-outside';
import Counter from '../../components/Counter.vue';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ShowcaseItemWide from '../../components/ShowcaseItemWide.vue';
import ShowcaseItem from '../../components/ShowcaseItem.vue';
import Paging from '../../components/Paging';
import { Dictionary } from '../../modules/Dictionary';
import { Api } from '../../modules/Api';
import utilFormatPrice from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';

import { Head } from '@vueuse/head';

import { TYPE, LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
  url: {
    currentPages: 'page',
  },
  components: {
    Footer,
    MainNav,
    ShowcaseItemWide,
    Slider,
    Counter,
    vSelect,
    Paging,
    ShowcaseItem,
    Loading,
    Banner,
    Head,
  },
  data() {
    return {
      isLoading: false,
      api: new Api(this.$t),
      LANGUAGE: LANGUAGE,
      showAdvanced: false,
      isShownMobileSorting: false,
      isShownMobileFiltering: false,
      priceLimit: {
        czk: 100000,
        eur: 4000,
      },
      price: [0, 0],
      searchedCruises: [],
      adultsNum: 2,
      childrenNum: 0,
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment(
        new Date(new Date().setYear(new Date().getFullYear() + 2))
      ).format('YYYY-MM-DD'),
      transportIncluded: false,
      isCrewPopupShown: false,
      isDatePopupShown: false,
      destination: 'Kamkoliv',
      destinations: [],
      currentPage: 1,
      maxPage: 1,
      sortBy: 'soon',
      totalComfort: false,
      cruiseDaysMax: 'Libovolná délka',
      ports: [],
      departurePort: 'Libovolný přístav',
      newAdultsNum: 0,
      newChildrenNum: 0,
      priceType: 'single',
      searching: false,
      canSearch: true,
      shipCode: 'Jakákoliv',
      cruisers: [],
      banners: [],
      cruiseOneWay: 'Jakákoliv',
      onlyPromo: false,
    };
  },
  computed: {
    adultsLimit() {
      return 5 - this.childrenNum;
    },
    childrenLimit() {
      return 5 - this.adultsNum;
    },
    adultsInflected() {
      return dict.getWord('adults', this.adultsNum);
    },
    childrenInflected() {
      return dict.getWord('children', this.childrenNum);
    },
    langDestinations() {
      const langDestinations = this.destinations.map((destination) => {
        destination.langName = destination.name[LANGUAGE];
        return destination;
      });
      return langDestinations;
    },
    langCruisers() {
      const langCruisers = this.cruisers.map((cruiser) => {
        cruiser.langName = cruiser.name[LANGUAGE];
        return cruiser;
      });
      return langCruisers;
    },
    langPorts() {
      const langPorts = this.ports.map((port) => {
        port.langName = port.name[LANGUAGE];
        return port;
      });

      const anyPortLangs = {
        cs: this.$t('LABELS.any-port'),
        sk: this.$t('LABELS.any-port'),
      };

      return [
        {
          code: this.$t('LABELS.any-port'),
          added: true,
          langName: anyPortLangs[LANGUAGE],
        },
        ...langPorts,
      ];
    },
    langDaysMax() {
      const options = [
        {
          durationMin: 0,
          durationMax: this.$t('LABELS.any-duration'),
          name: {
            cs: this.$t('LABELS.any-duration'),
            sk: this.$t('LABELS.any-duration'),
          },
        },
        {
          durationMin: 1,
          durationMax: 6,
          name: {
            cs: this.$t('LABELS.duration-1-6-days'),
            sk: this.$t('LABELS.duration-1-6-days'),
          },
        },
        {
          durationMin: 7,
          durationMax: 10,
          name: {
            cs: this.$t('LABELS.duration-7-10-days'),
            sk: this.$t('LABELS.duration-7-10-days'),
          },
        },
        {
          durationMin: 10,
          durationMax: 16,
          name: {
            cs: this.$t('LABELS.duration-10-16-days'),
            sk: this.$t('LABELS.duration-10-16-days'),
          },
        },
        {
          durationMin: 16,
          durationMax: this.$t('LABELS.duration-16-and-more-days-label'),
          name: {
            cs: this.$t('LABELS.duration-16-and-more-days'),
            sk: this.$t('LABELS.duration-16-and-more-days'),
          },
        },
      ];

      const langMaxDaysOptions = options.map((option) => {
        option.langName = option.name[LANGUAGE];
        return option;
      });

      return langMaxDaysOptions;
    },
    onlyPromosOptions() {
      const options = [
        {
          onlyPromo: false,
          name: {
            cs: this.$t('LABELS.all-sails'),
            sk: this.$t('LABELS.all-sails'),
          },
        },
        {
          onlyPromo: true,
          name: {
            cs: this.$t('LABELS.promo-cruises'),
            sk: this.$t('LABELS.promo-cruises'),
          },
        },
      ];

      const onlyPromosOptions = options.map((option) => {
        option.langName = option.name[LANGUAGE];
        return option;
      });

      return onlyPromosOptions;
    },
    oneWaySails() {
      const options = [
        {
          value: this.$t('LABELS.anywhat'),
          name: {
            cs: this.$t('LABELS.anywhat'),
            sk: this.$t('LABELS.anywhat'),
          },
        },
        {
          value: 'yes',
          name: {
            cs: this.$t('LABELS.yes'),
            sk: this.$t('LABELS.yes'),
          },
        },
        {
          value: 'no',
          name: {
            cs: this.$t('LABELS.no'),
            sk: this.$t('LABELS.no'),
          },
        },
      ];

      const oneWayOptions = options.map((option) => {
        option.langName = option.name[LANGUAGE];
        return option;
      });

      return oneWayOptions;
    },
  },
  methods: {
    showMobileSorting() {
      this.isShownMobileSorting = true;
    },
    hideMobileSorting() {
      this.isShownMobileSorting = false;
    },
    showMobileFiltering() {
      this.isShownMobileFiltering = true;
    },
    hideMobileFiltering() {
      this.isShownMobileFiltering = false;
    },
    toggleAdvanced() {
      this.showAdvanced = !this.showAdvanced;
    },
    hideCrewPopup() {
      if (this.isCrewPopupShown === true) {
        this.isCrewPopupShown = false;

        this.search();
        this.saveUrlFilters({
          adultsNum: this.adultsNum,
          childrenNum: this.childrenNum,
        });
      }
    },
    showCrewPopup() {
      if (this.isCrewPopupShown === false) {
        this.isCrewPopupShown = true;
      }
    },
    hideDatePopup() {
      if (this.isDatePopupShown === true) {
        this.isDatePopupShown = false;
      }
    },
    showDatePopup() {
      if (this.isDatePopupShown === false) {
        this.isDatePopupShown = true;
      }
    },
    updateAdultsNum(num) {
      this.adultsNum = num;
    },
    updateChildrenNum(num) {
      this.childrenNum = num;
    },
    async search(page = 1) {
      if (this.canSearch === false) {
        return null;
      }

      this.searching = true;

      const filters = {
        destination: !this.langDestinations.find(
          (destination) => destination.code === this.destination
        )?.added
          ? this.destination
          : null,
        departureDayFrom: this.startDate,
        departureDayTo: this.endDate,
        adultCount: this.adultsNum,
        childCount: this.childrenNum,
        transportIncluded: this.transportIncluded,
        page,
        orderBy: this.sortBy,
        totalComfort: this.totalComfort,
        cruiseDaysMin: this.langDaysMax.find(
          (maxDays) => maxDays.durationMax === this.cruiseDaysMax
        )?.durationMin,
        cruiseDaysMax: this.cruiseDaysMax,
        departurePort: !this.langPorts.find(
          (port) => port.code === this.departurePort
        )?.added
          ? this.departurePort
          : null,
        priceMin: this.price[0],
        priceMax:
          this.price[1] >= this.priceLimit[this.$store.currency]
            ? null
            : this.price[1],
        priceCurrency: this.$store.currency,
        shipCode: !this.langCruisers.find((ship) => ship.code === this.shipCode)
          ?.added
          ? this.shipCode
          : null,
        cruiseOneWay: this.cruiseOneWay,
        onlyPromo: this.onlyPromo,
      };

      const { meta: searchMeta, data: searchData } =
        await this.api.searchCruises(filters);

      this.handleSearchResults(searchMeta, searchData);

      this.searching = false;
    },
    handleShownPrice(cruises) {
      cruises.forEach((cruise) => {
        cruise.singleForTwoPrice = JSON.parse(
          JSON.stringify(cruise.priceDouble)
        );
        Object.keys(cruise.singleForTwoPrice).forEach((currency) => {
          cruise.singleForTwoPrice[currency].amount_decimal /= 2;
          cruise.singleForTwoPrice[currency].amount_int /= 2;
        });

        cruise.fullPrice = JSON.parse(JSON.stringify(cruise.full_price));
        Object.keys(cruise.fullPrice).forEach((currency) => {
          cruise.fullPrice[currency].amount_decimal /= 2;
          cruise.fullPrice[currency].amount_int /= 2;
        });
      });
    },
    handleSearchResults(meta, results) {
      this.handleShownPrice(results);
      this.searchedCruises = results;
      this.maxPage = meta.maxPage;
      this.currentPage = meta.currentPage;
    },
    changePage(newPage) {
      this.currentPage = newPage;
      this.search(this.currentPage);
      window.scrollTo(0, 0);
    },
    async loadPorts() {
      const ports = await this.api.loadPorts(true);
      this.ports = ports;
    },
    fillPrice() {
      this.price = [0, this.priceLimit[this.$store.currency]];
    },
    priceChanged() {
      this.search();
      this.saveUrlFilters({ priceMin: this.price[0] });
      setTimeout(() => {
        this.saveUrlFilters({ priceMax: this.price[1] });
      }, 1);
    },
    destinationChanged() {
      this.search();
      this.saveUrlFilters({ destination: this.destination });
    },
    shipCodeChanged() {
      this.search();
      this.saveUrlFilters({ shipCode: this.shipCode });
    },
    loadUrlFilters() {
      const allowedFilters = [
        'priceMin',
        'priceMax',
        'adultsNum',
        'childrenNum',
        'startDate',
        'endDate',
        'transportIncluded',
        'destination',
        'sortBy',
        'totalComfort',
        'cruiseDaysMax',
        'departurePort',
        'priceType',
        'shipCode',
        'cruiseOneWay',
        'onlyPromo',
      ];
      const hash = this.$route.hash.slice(1);

      this.canSearch = false;
      hash.split('&').forEach((param) => {
        const [key, val] = param.split('=');

        if (allowedFilters.indexOf(key) !== -1) {
          if (key === 'priceMin') {
            this.price = [val, this.price[1]];
          } else if (key === 'priceMax') {
            this.price = [this.price[0], val];
          } else if (key === 'adultsNum') {
            if (val > this.adultsLimit) {
              this.adultsNum = this.adultsLimit;
              this.newAdultsNum = this.adultsLimit;
            } else {
              this.adultsNum = isNaN(Number(val)) ? 0 : Number(val);
              this.newAdultsNum = isNaN(Number(val)) ? 0 : Number(val);
            }
          } else if (key === 'childrenNum') {
            if (val > this.childrenLimit) {
              this.childrenNum = this.childrenLimit;
              this.newChildrenNum = this.childrenLimit;
            } else {
              this.childrenNum = isNaN(Number(val)) ? 0 : Number(val);
              this.newChildrenNum = isNaN(Number(val)) ? 0 : Number(val);
            }
          } else {
            if (!isNaN(Number(val))) {
              this[key] = Number(val);
            } else if (val === 'true' || val === 'false') {
              this[key] = val === 'true' ? true : false;
            } else {
              if (val !== undefined || val?.length === 0) {
                this[key] = val;
              }
            }
          }
        }
      });
      this.canSearch = true;
    },
    saveUrlFilters(filters) {
      const prevScroll = document.documentElement.scrollTop;
      const hash = this.$route.hash.slice(1);
      const hashParams = {};
      if (hash.length > 0) {
        hash.split('&').forEach((param) => {
          const [key, val] = param.split('=');
          hashParams[key] = val;
        });
      }

      Object.keys(filters).forEach((filterKey) => {
        hashParams[filterKey] = filters[filterKey];
      });

      let finalUrlHash = '#';
      Object.keys(hashParams).forEach((hashKey, i) => {
        if (i !== 0) {
          finalUrlHash += '&';
        }
        if (!hashParams[hashKey]) {
          finalUrlHash += `${hashKey}`;
        } else {
          finalUrlHash += `${hashKey}=${hashParams[hashKey]}`;
        }
      });

      if (Object.keys(hashParams).length > 0) {
        this.$router.push({ hash: finalUrlHash });
      }

      setTimeout(() => {
        window.scrollTo(0, prevScroll);
      }, 1);
    },
    formatPrice(price) {
      return utilFormatPrice(price);
    },
    async loadDestinations() {
      this.destinations = await this.api.getDestinations();
    },
    async loadCruisers() {
      this.cruisers = await this.api.getShips();
    },
    setTranslatedDefaults() {
      this.destination = this.$t('LABELS.anywhere');
      this.shipCode = this.$t('LABELS.anywhat');
      this.departurePort = this.$t('LABELS.any-port');
      this.cruiseDaysMax = this.$t('LABELS.any-duration');
      this.onlyPromo = this.$t('LABELS.all-sails');
    },
    async loadBanners() {
      this.banners =
        (await this.api.getBanner(TYPE, LANGUAGE, 'search', true)) ||
        this.banners;
    },
  },
  beforeMount() {
    this.setTranslatedDefaults();
    this.loadUrlFilters();
  },
  async mounted() {
    this.isLoading = true;
    this.loadBanners();
    await this.loadDestinations();
    await this.loadCruisers();
    await this.loadPorts();
    this.fillPrice();
    this.isLoading = false;
    this.search();
  },
  watch: {
    sortBy() {
      this.search();
      this.saveUrlFilters({ sortBy: this.sortBy });
    },
    // adultsNum() {
    //     this.search();
    //     this.saveUrlFilters({adultsNum: this.adultsNum});
    // },
    // childrenNum() {
    //     this.search();
    //     this.saveUrlFilters({childrenNum: this.childrenNum});
    // },
    startDate() {
      this.search();
      this.saveUrlFilters({ startDate: this.startDate });
    },
    endDate() {
      this.search();
      this.saveUrlFilters({ endDate: this.endDate });
    },
    totalComfort() {
      this.search();
      this.saveUrlFilters({ totalComfort: this.totalComfort });
    },
    cruiseDaysMax() {
      this.search();
      this.saveUrlFilters({ cruiseDaysMax: this.cruiseDaysMax });
    },
    departurePort() {
      this.search();
      this.saveUrlFilters({ departurePort: this.departurePort });
    },
    transportIncluded() {
      this.search();
      this.saveUrlFilters({ transportIncluded: this.transportIncluded });
    },
    cruiseOneWay() {
      this.search();
      this.saveUrlFilters({ cruiseOneWay: this.cruiseOneWay });
    },
    onlyPromo() {
      this.search();
      this.saveUrlFilters({ onlyPromo: this.onlyPromo });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
